<template>
  <el-dialog title="参与详情"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             :modal-append-to-body="false"
             width="50%">
    <table-page dataName="list"
                ref="table"
                init
                :params="paramsObj"
                :request="knightMemberDetail">
      <el-table-column prop="equity_id"
                       show-overflow-tooltip
                       label="权益id">
      </el-table-column>
      <el-table-column prop="equity_name"
                       show-overflow-tooltip
                       label="权益名称">
      </el-table-column>
      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="参与时间">
      </el-table-column>
    </table-page>
    <span slot="footer"
          class="dialog-footer">

    </span>
  </el-dialog>
</template>

<script>
import TablePage from '@/components/TablePage'
import { knightMemberDetail } from '@/api/knight'
export default {
  props: ['params'],
  data () {
    return {
      knightMemberDetail,
      dialogVisible: false,
      paramsObj: {

      },
      search: [{
        type: 'input',
        placeholder: '请输入商户号',
        key: 'keyword',
        value: ''
      }]
    }
  },
  components: {
    TablePage
  },

  methods: {
    showTable (id) {
      this.paramsObj = {
        member_id: id
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
