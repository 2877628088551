<template>
  <div class="container">
    <table-page dataName="list"
                ref="table"
                :search="search"
                :searchFun="searchFun"
                :request="knightMembers">
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号">
      </el-table-column>
      <el-table-column prop="id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="openID">
      </el-table-column>
      <el-table-column prop="index"
                       show-overflow-tooltip
                       label="会员等级">
        <template slot-scope="scope">
          {{scope.row.level === 1 ?'普通会员':'高级会员'}}
        </template>
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="注册时间">
      </el-table-column>
      <el-table-column prop="upgrade_at"
                       show-overflow-tooltip
                       label="升级时间">
      </el-table-column>
      <el-table-column prop="receive_num"
                       show-overflow-tooltip
                       label="领取权益次数">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="$refs.equities.showTable(scope.row.id)">参与详情</el-button>
        </template>
      </el-table-column>
    </table-page>
    <Equities ref="equities" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import Equities from './components/Equities'
import { knightMembers, delEquity } from '@/api/knight'
export default {
  name: 'Equity',
  data () {
    return {
      knightMembers,
      search: [{
        type: 'input',
        name: '姓名',
        placeholder: '请输入姓名',
        key: 'name',
        value: ''
      }, {
        type: 'input',
        name: '手机号',
        placeholder: '请输入手机号',
        key: 'tel',
        value: ''
      }, {
        type: 'input',
        name: 'openid',
        placeholder: '请输入openid',
        key: 'openid',
        value: ''
      }, {
        type: 'select',
        value: '',
        key: 'knights_level',
        name: '会员等级',
        list: [{
          value: 1,
          label: '普通会员'
        }, {
          value: 2,
          label: '高级会员'
        }]
      }],
      searchParams: {}
    }
  },
  components: {
    TablePage,
    Equities
  },
  methods: {
    showTabList (scope) {
      this.$refs.tabs.showTabList(scope)
    },
    searchFun (res) {
      const param = Object.assign({}, res)
      param.province = res.addressProvince
      param.city = res.addressCity
      param.district = res.addressDistrict
      delete param.addressProvince
      delete param.addressCity
      delete param.addressDistrict
      return param
    },
    delEquity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delEquity({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
